.navbar {

  .back-navigation {
    color: white;
    &::before {
      content: '';
      background: url('/src/assets/svg/icon__arrow--white.svg');
      display: inline-block;
      width: 14px;
      height: 11px;
      margin-right: $spacer;
    }
    &:hover {
      text-decoration: none;
    }
  }

}
