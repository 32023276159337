.spinner {
  display: flex;
  justify-content: center;
  &--overlay {
    width: 100%;
    height: 100vh;
    text-align: center;
    z-index: 1001;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
}
