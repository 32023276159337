.horizontal-dl {
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  dt {
    width: 30%;
  }
  dd {
    margin-left: auto;
    width: 70%;
  }
  &--even-split {
    dt, dd {
      width: 50%;
    }
  }
}
