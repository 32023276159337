.cart {

  .order-top {
    border-bottom: 1px solid;
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    dt, dd {
      font-size: 1.6rem;
    }
  }

  .cart-item {
    position: relative;
    border: 1px solid $black;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin-bottom: 1rem;

    .horizontal-dl {
      font-size: 1.6rem;
      margin-bottom: $spacer;
      line-height: 1.2;
      dt, dd {
        width: 100%
      }
      @include media-breakpoint-up(lg) {
        dt {
          width: 40%;
        }
        dd {
          width: 60%;
        }
      }

    }

    &__crossed-out {
      text-decoration: line-through;
    }

    &__selections {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
    }

    label {
      flex: 1;
      font-size: 1.4rem;
      position: relative;
      cursor: pointer;
      padding-left: $spacer * 3;
      margin-bottom: $spacer * 1.25;
      margin-left: 0;
      line-height: 1.2;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        align-self: center;
      }

      &:after {
        content: '';
        width: 2.4rem;
        height: 2.4rem;
        border: 1px solid grey;
        border-radius: 4rem;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      input {
        display: none;
      }
    }

    .active-checkbox {
      &:after {
        content: url('/src/assets/svg/icon__checked--white.svg');
        background-color: $blue;
        border: 1px solid $blue;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
