
@font-face {
	font-family: 'Mycall Gelion';
	src: url('#{$path-fonts}woff2/Mycall-Gelion-Thin.woff2') format('woff2'),
	url('#{$path-fonts}woff/Mycall-Gelion-Thin.woff') format('woff'),
	url('#{$path-fonts}ttf/Mycall-Gelion-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Mycall Gelion';
	src: url('#{$path-fonts}woff2/Mycall-Gelion-ThinItalic.woff2') format('woff2'),
	url('#{$path-fonts}woff/Mycall-Gelion-ThinItalic.woff') format('woff'),
	url('#{$path-fonts}ttf/Mycall-Gelion-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Mycall Gelion';
	src: url('#{$path-fonts}woff2/Mycall-Gelion-Light.woff2') format('woff2'),
	url('#{$path-fonts}woff/Mycall-Gelion-Light.woff') format('woff'),
	url('#{$path-fonts}ttf/Mycall-Gelion-Light.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Mycall Gelion';
	src: url('#{$path-fonts}woff2/Mycall-Gelion-LightItalic.woff2') format('woff2'),
	url('#{$path-fonts}woff/Mycall-Gelion-LightItalic.woff') format('woff'),
	url('#{$path-fonts}ttf/Mycall-Gelion-LightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Mycall Gelion';
	src: url('#{$path-fonts}woff2/Mycall-Gelion-Regular.woff2') format('woff2'),
	url('#{$path-fonts}woff/Mycall-Gelion-Regular.woff') format('woff'),
	url('#{$path-fonts}ttf/Mycall-Gelion-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Mycall Gelion';
	src: url('#{$path-fonts}woff2/Mycall-Gelion-RegularItalic.woff2') format('woff2'),
	url('#{$path-fonts}woff/Mycall-Gelion-RegularItalic.woff') format('woff'),
	url('#{$path-fonts}ttf/Mycall-Gelion-RegularItalic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Mycall Gelion';
	src: url('#{$path-fonts}woff2/Mycall-Gelion-Medium.woff2') format('woff2'),
	url('#{$path-fonts}woff/Mycall-Gelion-Medium.woff') format('woff'),
	url('#{$path-fonts}ttf/Mycall-Gelion-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Mycall Gelion';
	src: url('#{$path-fonts}woff2/Mycall-Gelion-MediumItalic.woff2') format('woff2'),
	url('#{$path-fonts}woff/Mycall-Gelion-MediumItalic.woff') format('woff'),
	url('#{$path-fonts}ttf/Mycall-Gelion-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Mycall Gelion';
	src: url('#{$path-fonts}woff2/Mycall-Gelion-SemiBold.woff2') format('woff2'),
	url('#{$path-fonts}woff/Mycall-Gelion-SemiBold.woff') format('woff'),
	url('#{$path-fonts}ttf/Mycall-Gelion-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Mycall Gelion';
	src: url('#{$path-fonts}woff2/Mycall-Gelion-SemiBoldItalic.woff2') format('woff2'),
	url('#{$path-fonts}woff/Mycall-Gelion-SemiBoldItalic.woff') format('woff'),
	url('#{$path-fonts}ttf/Mycall-Gelion-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Mycall Gelion';
	src: url('#{$path-fonts}woff2/Mycall-Gelion-Bold.woff2') format('woff2'),
	url('#{$path-fonts}woff/Mycall-Gelion-Bold.woff') format('woff'),
	url('#{$path-fonts}ttf/Mycall-Gelion-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Mycall Gelion';
	src: url('#{$path-fonts}woff2/Mycall-Gelion-BoldItalic.woff2') format('woff2'),
	url('#{$path-fonts}woff/Mycall-Gelion-BoldItalic.woff') format('woff'),
	url('#{$path-fonts}ttf/Mycall-Gelion-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Mycall Gelion';
	src: url('#{$path-fonts}woff2/Mycall-Gelion-Black.woff2') format('woff2'),
	url('#{$path-fonts}woff/Mycall-Gelion-Black.woff') format('woff'),
	url('#{$path-fonts}ttf/Mycall-Gelion-Black.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Mycall Gelion';
	src: url('#{$path-fonts}woff2/Mycall-Gelion-BlackItalic.woff2') format('woff2'),
	url('#{$path-fonts}woff/Mycall-Gelion-BlackItalic.woff') format('woff'),
	url('#{$path-fonts}ttf/Mycall-Gelion-BlackItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
	font-display: swap;
}
