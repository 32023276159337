.steps {
  max-width: 500px;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @extend .list-unstyled;

  .step {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .numbercircle {
    margin-bottom: $spacer;
    border-radius: 50%;
    font-size: 2rem;
    background-color: $gray-lighter;
    color: $blue;
    height: 50px;
    width: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: relative;

    &.done {
      background-color: $blue;
      color: white;
      cursor: pointer;
      &.current {
        background-color: $yellow;
        color: $blue;
        cursor: auto;
      }
    }
  }

  li:not(:last-child) {
    .numbercircle {
      &::after {
        content: '';
        border-top: 2px dashed $blue;
        position: absolute;
        top: 25px;
        width: 120px;
        left: 50px; // same as circle width
        z-index: 1;
      }
    }
  }

  .multi-steptext {
    text-align: center;
    p {
      font-size: 1.6rem;
    }
    &.done {
      cursor: pointer;
      &.current {
        font-weight: bold;
        cursor: auto;
      }
    }
  }
}
