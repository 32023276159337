.react-datepicker-wrapper {
  display: block;
}

/* react datepicker */
.react-datepicker-popper {
  z-index: 2000;
}
.react-datepicker {
  font-size: 1.8rem;
  &__current-month,
  &-time__header,
  &-year-header {
    font-size: 1.8rem;
    margin-bottom: 0.6rem;
  }
  &__day-name,
  &__day,
  &__time-name {
    width: 40px;
    height: 40px;
    line-height: 2.2;
    margin: 0.4rem;
  }
  &__day {
    &--selected, &--keyboard-selected {
      background-color: $blue;
      &:hover {
        background-color: $blue-dark;
      }
    }
  }
  &__header {
    padding-top: 0.8em;
  }
  &__navigation {
    top: 1em;
    line-height: 1.7em;
    &:hover :before {
      border-color: $primary;
      opacity: 0.8;
    }
    &--previous {
      left: $spacer;
    }
    &--next {
      right: $spacer;
    }
  }
  &__navigation-icon {
    &:before {
      width: 10px;
      height: 10px;
      border-color: $primary;
    }
  }
}
