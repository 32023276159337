// TODO: Clean up this file... 90% can be removed or moved to other files.

html {
	font-size: 62.5%;
}

/* Login page */
.login {
	min-height: 100vh;
	display: flex;
	align-items: center;
}

.version {
	position: fixed;
	margin: 0;
	left: $spacer;
	bottom: $spacer;
}

/* pages */
.page {
	min-height: 100vh;

	&--lang-menu {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&::after {
			content: '';
			width: 80px;
			height: 90px;
			position: fixed;
			bottom: $spacer * 2;
			right: $spacer * 2;
			background-image: url('/src/assets/svg/mycall-logo-vertical--dark.svg');
			background-repeat: no-repeat;
		}
	}

	&#porting,
	&#topuppage,
	&#recenttopups {
		.topupblock {
			margin-bottom: 5rem;
		}
		.mh-600 {
			min-height: 600px;
		}

		.cardpointer {
			cursor: pointer;
		}
	}
}

.order-total {
	font-size: 3rem;
	margin-bottom: $spacer;
}

/* grid */
.container-fluid {
	max-width: 1000px;
	&.narrow {
		max-width: 500px;
	}
}

/**/
.main-content {
	min-height: calc(100vh - 60px);
	display: flex;
	align-items: center;
	padding: $spacer * 2 0;
}
.content-header {
	margin-bottom: $spacer * 3;
}

/* Forms */
.form-group {
	margin-top: $spacer * 2;
	&:first-child {
		margin-top: 0;
	}
	&:only-child {
		margin: 0;
	}
}

/**/
.box {
	border-left: 1px solid #d5d4d4;
	padding: 0 $spacer * 3;
	h2 {
		margin-bottom: $spacer * 2;
	}
}

//__Location
.location {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&::after {
		content: '';
		width: 80px;
		height: 90px;
		position: fixed;
		bottom: $spacer * 2;
		right: $spacer * 2;
		background-image: url('/src/assets/svg/mycall-logo-vertical--dark.svg');
		background-repeat: no-repeat;
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;

		h1 {
			margin-bottom: 3rem;
		}

		form {
			display: flex;
			flex-direction: column;

			label {
				position: relative;
				cursor: pointer;
				margin-bottom: $spacer;

				&::before {
					content: '';
					width: 2.4rem;
					height: 2.4rem;
					border: 1px solid grey;
					border-radius: 4rem;
					position: absolute;
					top: calc(50% - 1.2rem);
					left: -$spacer * 3;

				}
				&.active {
					&::before {
						content: "";
						background-image: url('/src/assets/svg/icon__checked--white.svg');
						background-repeat: no-repeat;
						background-position: center;
						background-color: $blue;
						border: 1px solid $blue;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}

			input {
				display: none;
			}
		}
	}

	&__buttons-wrapper {
		display: flex;
		margin-top: 2rem;
	}
}

.inactive {
	background-color: grey;
	border: 2px solid grey;
	pointer-events: none;
}
