/* buttons */
.btn {
  //font-size: 2.4rem;
  //padding: $spacer * 1.2 $spacer * 3 $spacer * 0.5;

  &-success {
    background-color: $success;
    border-color: $success;
    color: $success-darker;
    &:hover {
      background-color: $success-dark;
      border-color: $success-dark;
      color: $success-darker;
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus {
      background-color: $success-darker;
      border-color: $success-darker;
      color: $success;
    }
    &.disabled, &:disabled {
      color: $success-darker;
      &:hover {
        background-color: $success;
        border-color: $success;
      }
    }
  }

  &-lang {
    //padding: $spacer * 1.5 $spacer * 2 $spacer * 0.7;
    color: theme-color('primary');
    border-radius: 12px;
    background: #fff;
    display: block;
    width: 100%;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
    white-space: normal;

    .header & {
      padding: $spacer $spacer * 2 $spacer;
      font-size: 1.8rem;
    }

    &:hover {
      border: $border-width solid #fff;
      background: none;
      color: #fff;
    }

    &-lg {
      //padding: $spacer * 3 $spacer * 3 $spacer * 1.9;
      min-height: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background: theme-color('primary');
      }
    }
  }

  //&.disabled,
  //&:disabled {
  //  background: #cecece;
  //  border-color: #cecece;
  //}

  &-switch {
    background: #fff;
    padding: 1px 2px !important;
    height: 33px !important;
    width: 62px;
    margin: auto $spacer;
    &::before {
      content: '';
      height: 25px;
      width: 25px;
      display: block;
      border-radius: 50%;
      background: #c4c4c4;
      border: 1px solid #bbb9b9;
      box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.28);
    }
    &.active {
      &::before {
        float: right;
      }
    }
  }

  &-icon {
    background: pink;
    border: 0;

    &::after {
      display: none;
    }
    &--user {
      background: url('/src/assets/svg/icon__user.svg') no-repeat;
      background-size: contain;
      width: 42px !important;
      height: 42px !important;
      cursor: pointer;
      &:active,
      &:focus {
        background: url('/src/assets/svg/icon__user.svg') no-repeat;
        background-size: contain;
        outline: 0;
      }
    }
  }

  &-outline-secondary:hover {
    color: theme-color('primary');
  }

  .col-sm-3 & {
    width: 100%;
  }

  &-close {
    position: absolute;
    background-repeat: no-repeat;
    top: 10px;
    right: 10px;
  }

}

.logout-btn {
  font-size: 1.8rem;
  margin-top: 1rem;
  white-space: nowrap;
}
